<script>

import { getInAppNotificationsRequest } from '@/API/requests';
export default {
  data() {
    return {
      whitelisted_urls: ["/app/payment-wall", "/app/subscription-plan-payment"],
      paywall_url: "/app/payment-wall",
      paywall_disabled: true,
    };
  },
  methods: {
    handleUserSubscription() {
      if (this.paywall_disabled) {
        return;
      }
      // The paymentlink contains is either null or has
      // the uuid for a subscription payment transaction
      // It is what we will use to redirect the user to the paywall
      const { payment_link } = this.$store.state.auth.accountStatus;

      // We want the paywall to work only on post-auth pages
      if (!window.location.pathname.startsWith("/app")) {
        return;
      }
      // If the payment link is null or the user is in one of the whitelisted pages
      // then don't redirect
      if (
        !payment_link ||
        this.whitelisted_urls.includes(window.location.pathname)
      ) {
        return;
      }
      // redirect to the paywall page
      window.location.href = this.paywall_url;
    },
    async handleNotifications() {
      try {
        const { data: notifications } = await getInAppNotificationsRequest();

        const red = document.getElementById("red_dot");
        const bell = document.getElementById("bell_icon");

        if (!red || !bell) {
          return;
        }

        const unreadNotifications = notifications.filter(n => !n.read_at);
        if (unreadNotifications.length) {
          red.style.display = "block";
        }

        function handleClick() {
          let opacity = 1;
          const intervalId = setInterval(function () {
            opacity -= 0.1;
            red.style.opacity = opacity;
            if (opacity <= 0) {
              clearInterval(intervalId);
              red.style.display = "none";
            }
          }, 30);
        }

        bell.addEventListener("click", handleClick);

        const targetElement = document.getElementById("notifications_wrapper");
        if (!targetElement) return;
        targetElement.innerHTML = '';

        if (notifications && notifications.length > 0) {
          notifications.forEach((item) => {
            const parsedData = typeof item.data === 'string' ? JSON.parse(item.data) : item.data;
            const href = parsedData.cta?.url || "#";
            const message = parsedData.body || parsedData.message;
            const cta = parsedData.cta?.text || null;
            const relativetime = { relative_time: item.relative_time };
            const type = parsedData.type || 'info';

            const notification = renderNotification(
              href,
              message,
              cta,
              relativetime,
              type
            );
            targetElement.appendChild(notification);
          });
        } else {
          const emptyState = document.createElement("div");
          emptyState.classList.add("text-size-small", "text-center", "p-4");
          emptyState.textContent = "No notifications";
          targetElement.appendChild(emptyState);
        }

        function renderNotification(href, message, cta, relativetime, type) {
          const notification = document.createElement("a");
          notification.href = href;
          notification.classList.add("notification", "w-inline-block");
          notification.tabIndex = 0;

          const iconWrapper = document.createElement("div");
          iconWrapper.classList.add("notification-icon-wrapper");

          let imageUrl;
          switch (type) {
            case "danger":
              imageUrl = "/images/danger.png";
              break;
            case "warning":
              imageUrl = "/images/warning.png";
              break;
            case "info":
            default:
              imageUrl = "/images/default.png";
              break;
          }

          const iconImage = document.createElement("img");
          iconImage.src = imageUrl;
          iconImage.loading = "lazy";
          iconImage.alt = "";

          iconWrapper.appendChild(iconImage);

          const textWrapper = document.createElement("div");
          textWrapper.classList.add("notification-text-wrapper");

          const ctaWrapper = document.createElement("div");
          ctaWrapper.classList.add("notification-cta-wrapper");

          const text = document.createElement("div");
          text.classList.add("text-size-small");
          text.textContent = message;

          const button = document.createElement("div");
          button.classList.add("button", "is-button-xsmall");

          const buttonText = document.createElement("div");
          buttonText.classList.add("text-size-tiny");
          buttonText.textContent = cta || "View";

          button.appendChild(buttonText);

          const mTop = document.createElement("div");
          mTop.classList.add("margin-top-custom");
          mTop.appendChild(button);

          ctaWrapper.appendChild(text);
          if (cta !== "#") {
            ctaWrapper.appendChild(mTop);
          }

          textWrapper.appendChild(ctaWrapper);

          const timeWrapper = document.createElement("div");
          timeWrapper.classList.add("notification-time-wrapper");

          const time = document.createElement("div");
          time.classList.add("text-size-tiny", "text-color-ap-blue-50");
          time.textContent = relativetime.relative_time;

          timeWrapper.appendChild(time);

          notification.appendChild(iconWrapper);
          notification.appendChild(textWrapper);
          notification.appendChild(timeWrapper);

          return notification;
        }

      } catch (error) {
        console.error('Error fetching notifications:', error);
        if (error.response?.status === 401) {
          this.logoutAndRedirect();
        }

        const targetElement = document.getElementById("notifications_wrapper");
        if (targetElement) {
          targetElement.innerHTML = `
        <div class="text-size-small text-center text-red-500 p-4">
          Failed to load notifications
        </div>
      `;
        }
      }
    },
    logoutAndRedirect(e = null) {
      if (e) {
        e.preventDefault();
      }

      this.$store.dispatch("auth/logout").then(() => {
        window.location.href = "/auth/logout";

      })
    },
    logout() {
      localStorage.clear();
      sessionStorage.clear();
    },

    authMiddleWare() {
      if (
        window.location.pathname.startsWith("/app") &&
          window.location.pathname !== "/app/subscription-plan-payment" &&
          window.location.pathname !== "/app/payment-success" &&
          window.location.pathname !== "/app/payment-failed"
      ) {
        if (!this.$store.state.auth.token || !this.$store.state.auth.user) {
          window.location.href = window.location.origin + "/auth/log-in";
        }
      }
    },
  },
  created() {
    //We need to set this to false everytime the page loads to re re-authentication process to be restarted
    this.$store.commit("auth/setAuthInProgress", false)

  },
  async mounted() {
    this.authMiddleWare();
    if (this.$store.state.auth.token) {
      const logoutButton = document.querySelector(`a[href="/auth/logout"]`);
      if (logoutButton) {
        logoutButton.addEventListener("click", this.logoutAndRedirect);
      }
      const dateDifferenceInMinutes = () => {
        return Math.round(
            ((new Date().getTime() -
            new Date(
              this.$store.state.auth.notification_last_fetched_at
            ).getDate()) /
            (1000 * 60)) %
            60
        );
      };
      if (
        dateDifferenceInMinutes() > 20 &&
        window.location.pathname.startsWith("/app")
      ) {
        this.$store.dispatch("auth/checkAccountStatus");
        this.$store
          .dispatch("auth/getNotifications")
          .then(this.handleNotifications)
          .catch((err) => {
            if (err.response?.status === 401) {
              this.logoutAndRedirect();
            }
          });
      }
    }
  },
};
</script>
